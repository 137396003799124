import React from 'react';
//import { Message, Dimmer, Loader } from 'semantic-ui-react';

class Footer extends React.Component {
    render() {
        return (
            <footer>
            <div style={{ width: '100%', position: 'fixed', bottom: "0", border: '1px Solid Blue'}}>
                <div style={{ position: "relative", bottom: "0", width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className="ui horizontal  segments" >

                        <div className="ui segment">
                            <span>This is the footer</span>
                        </div>
 
                    </div>

                </div>

            </div>
            </footer>
        );
    }
}
 
export default Footer; 