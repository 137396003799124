import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageContent from './PageContent';
import Header from './Header';
import Footer from './Footer';
import InvitedList from './InvitedList';
import RSVP from './RSVP';
import Gallery from './Gallery';
import Layout from './Layout';
//import logo from '../logo.svg';


// Create a react component
class App extends React.Component {
   
  render() {

      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<PageContent />} />
              <Route path="rsvp" element={<RSVP />} />
              <Route path="inv" element={<InvitedList />} />
              <Route path="*" element={<Gallery />} />
            </Route>
          </Routes>
        </BrowserRouter>
      );
  }
} 

export default App;
