import React from "react";
import { Input, Form, Checkbox } from 'semantic-ui-react';
//import { SingleGuest } from "../models/SingleGuest";

class RSVPSingleGuest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            singleGuest: this.props.guest
        };

    }

    onGuestChanged = () => {
        this.props.onSingleGuestChanged(this.state.singleGuest);
    }

    on19yoChanged = (e, {checked}) => {
        var sg = this.state.singleGuest;
        sg.isUnder19 = checked;
        this.setState({singleGuest: sg});
        //this.state.singleGuest.isUnder19 = checked;
        //console.log("isUnder19:::", checked);
        this.onGuestChanged();
    }

    on12yoChanged = (e, {checked}) => {
        var sg = this.state.singleGuest;
        sg.isUnder12 = checked;
        this.setState({singleGuest: sg});
        //this.state.singleGuest.isUnder12 = checked;
        //console.log("isUnder12:::", checked);
        this.onGuestChanged();
    }

    onNameChanged = (guestName) => {
        var sg = this.state.singleGuest;
        sg.name = guestName;
        this.setState({singleGuest: sg});
        //this.state.singleGuest.name = guestName;
        //console.log("name:::", guestName);
        this.onGuestChanged();
    }

    onDietaryChanged = (guestDietaryRestrictions) => {
        var sg = this.state.singleGuest;
        sg.dietaryRestrictions = guestDietaryRestrictions;
        this.setState({singleGuest: sg});
        //this.state.singleGuest.dietaryRestrictions = guestDietaryRestrictions;
        //console.log("dietary:::", guestDietaryRestrictions);
        this.onGuestChanged();
    }

    render() {
        return (  
            <Form.Field >  
                <div  style={{marginBottom: '20px', marginTop: '20px' }} key={'name'+this.state.singleGuest.orderId}>
                <div  style={{ display: 'inline'}}>
                 
                <Input type="text" id={'name'+this.state.singleGuest.orderId}  name={'name'+this.state.singleGuest.orderId}  
                        placeholder={'Please enter Full Name for Guest'+this.state.singleGuest.orderId} 
                        value={this.state.singleGuest.name}
                        onChange={(e) => this.onNameChanged(e.target.value) }  style={{width: '300px'}}/>
                </div>
                <div  style={{ marginLeft: '20px', display: 'inline'}}>
               
                <Checkbox label="Under 19 y.o." id={'under19'+this.state.singleGuest.orderId}  
                        name={'under19'+this.state.singleGuest.orderId} 
                        onChange={this.on19yoChanged}  />
                </div>
                <div  style={{  marginLeft: '20px', display: 'inline'}}>
                <Checkbox label="Under 12 y.o." id={'under12'+this.state.singleGuest.orderId}  name={'under12'+this.state.singleGuest.orderId} 
                        onChange={this.on12yoChanged}  />
                </div>
                <div  style={{ marginLeft: '20px', display: 'inline'}}>
                 
                <Input type="text" id={'dietaryRestrictions'+this.state.singleGuest.orderId}  
                        name={'dietaryRestrictions'+this.state.singleGuest.orderId}  
                        placeholder={'Please enter Dietary Restrictions for Guest'+this.state.singleGuest.orderId}
                        onChange={(e) => this.onDietaryChanged(e.target.value)} style={{width: '500px'}} />
                </div>
                </div>
            </Form.Field>
            );
    }
 

}

export default RSVPSingleGuest;
