import React from 'react';
//import { Message, Dimmer, Loader } from 'semantic-ui-react';
//import '../css/fonts.css';
// import '../css/lovestory.css'
//import '../css/gallery.css'

const LoveStory = () => {

    return (
        <section id="lovestory">

            <div className='outer-container'>
                <div className="love-header">
                     lov&#xE004;story           
                </div>
                
                <div className="container2">
                <div className="love-story-row1">

                  <div className="left-cell" >
                        <div className="frame-box1" >
                            <div className="frame-box2" >
                                <div className="frame-box">
                                    <div className='love-pink-header'>
                                    November 12, 2021
                                    </div>
                                    <div className='love-box-header'>
                                    First Date
                                    </div>
                                    <div className='love-box-text'>Nicole and Michael met each other for the first time after being introduced online by a mutual friend.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center-cell">
                        <div className="circle firstDate ">
                             &nbsp;
                        </div>
                    </div>
                    <div className="right-cell">&nbsp;</div>
                   
                </div>
                </div>
                
                <div className="container2">
                <div className="love-story-row">
                    <div className="left-cell" >
                        &nbsp;
                    </div>
                    <div className="center-cell">
                        <div className="circle relationship">
                            &nbsp;
                        </div>          
                    </div>
                    <div className="right-cell">
                       <div className="frame-box1" >
                            <div className="frame-box2" >
                                <div className="frame-box">
                                    <div className='love-pink-header'>
                                    December 22, 2021
                                    </div>
                                    <div className='love-box-header'>
                                    In A Relationship
                                    </div>
                                    <div className='love-box-text'>The couple did long distance for 2 years but visited each 
                                    other frequently and had many adventures together.</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>
                <div className="love-story-row">
                    <div className="left-cell" >
                        <div className="frame-box1" >
                            <div className="frame-box2" >
                                <div className="frame-box">
                                    <div className='love-pink-header'>
                                        July 16, 2022
                                    </div>
                                    <div className='love-box-header'>
                                        Got a dog
                                    </div>
                                    <div className='love-box-text'>Nicole and Michael brought Bella home 
                                    and they never had an uneventful day since.</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="center-cell">
                       <div className="circle gotDog">
                            &nbsp;
                        </div>
                    </div>
                    <div className="right-cell">&nbsp;</div>
                    
                </div>
                <div className="love-story-row">
                    <div className="left-cell" >
                        &nbsp;
                    </div> 
                    <div className="center-cell">
                        <div className="circle engaged">
                            &nbsp;
                           
                        </div>
                      
                    </div>
                    <div className="right-cell">
                        <div className="frame-box1" >
                            <div className="frame-box2" >
                                <div className="frame-box">
                                    <div className='love-pink-header'>
                                        July 31, 2023
                                    </div>
                                    <div className='love-box-header'>
                                        We're Engaged
                                    </div>
                                    <div className='love-box-text'>Michael proposed to Nicole ... she said yes!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="divider"></div>
                </div>
                </div>
        
            
        </section>
    );
};

export default LoveStory;