import React from "react";
import { Input, Header } from 'semantic-ui-react';

class RSVPSearch extends React.Component {
    state = { query: ''};

    onFormSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.query);
        console.log("this.props: ", this.props);
        this.props.onSubmit(this.state.query);
    }

    render() {
        return(
            <div className="ui segment" style={{ backgroundColor: '#e7a49b'}}>
                <form onSubmit={this.onFormSubmit} className="ui form">
                    <div className="field">
                        <Header as="h3" inverted color='#e6bdb6'>Please enter your name exactly as it appears on the invitation:</Header>
                        <Input fluid icon='search' placeholder='Search ...'
                        value={this.state.query}
                        onChange={(e) => this.setState({ query: e.target.value })} ></Input>
                    </div>
                </form>
            </div>

        );
    }

} 

export default RSVPSearch;