import React from 'react';

class TopSection extends React.Component {
    render() {
        return (
            <div id="home" className="hero set-bg"  >
                <div className="hero__text">
                    <div className="hero__div">
                        <span className="headervignettes">t</span>
                        <span className="timesnewromantext">THE WEDDING OF</span>
                        <span className="headervignettes">u</span>
                    </div>   
                    <div className="hero__div titletext">
                        Nicole & Michael
                    </div>
                    <div className="hero__div timesnewromantext">06.14.2025</div>
                </div>
            </div>
        );
    } 
}

export default TopSection;