import React from "react";
import axios from "axios";
import { Input, Header, Radio, Button, Form } from 'semantic-ui-react';
import RSVPSingleGuest from "./RSVPSingleGuest";
import { SingleGuest } from "../models/SingleGuest";

class RSVPRegistration extends React.Component {
     constructor(props) {
        super(props);

        this.state = {
            invId: this.props.data[0]._id,
            name: this.props.data[0].name,
            email: this.props.data[0].email,
            seats: this.props.data[0].seats,
            city: this.props.data[0].city,
            comment: this.props.data[0].comment,
            willAttend: this.props.data[0].willAttend,
            willAttendFirstTimeClicked: false,
            rsvpCompleted: this.props.data[0].rsvpCompleted,
            singleGuests: [...this.populateGuests(this.props.data[0])],
            emailAddressValidationMessage: "",
            submitSuccess: false
        };

    }

    

    validateEmail = (value) => {

        const isValidEmail = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;

        if(value && !value.match(isValidEmail)){
            this.setState({emailAddressValidationMessage: "Valid Email Address is required"});
            return false;
        } else if (!value) {
            this.setState({emailAddressValidationMessage: "Email Address is required"});
            return false;
        } else  {
            this.setState({emailAddressValidationMessage: ""});
            return true;
        }
    }

    populateGuests(inv) {

        var sgArr = [...Array(inv.seats).keys()];

        sgArr.map((i) => {

            var sg = new SingleGuest();
            var ind = i + 1;
            sg.orderId = ind;
            sg.invitationId = inv._id;  
            sg.isUnder19 = false;
            sg.isUnder12 = false;
            if(ind === 1){
                sg.name = inv.name;
            }

            sgArr[i] = sg;

            return sg;
        })

        return sgArr;

    }

    onWillAttendChanged = (e, {name, value}) => {
        
        console.log(name, ": ", value);
        this.setState({willAttend: (value==='true'), willAttendFirstTimeClicked: true});
    }

    onEmailChanged = (emailAddress) => {
        this.setState({email: emailAddress});
        const isValid = this.validateEmail(emailAddress);
        console.log("email:::", isValid);
    }

    onCommentChanged = (commentQuestion) => {
        this.setState({comment: commentQuestion});
        console.log("comment:::", commentQuestion);
    }

    onNoConfirmedButtonClicked = async () => {
        const invData = {
            name: this.state.name,
            email: this.state.email,
            city: this.state.city,
            seats: this.state.seats,
            comment: this.state.comment,
            willAttend: false, 
            rsvpCompleted: true
        };

        await axios.put(`${process.env.REACT_APP_BASE_URL}/invitations/${this.state.invId}`, invData)
        .then(res => {
            //const invitations = res.data;
            //console.log(invitations);

            window.location.replace("/");
        })
        .catch((error) => {
            console.log(error);
        });

    }

    onSingleGuestChanged = (guest) => {
        console.log("guest changed", guest);
        var sg = this.state.singleGuests;
        sg[guest.orderId-1] = guest;
        this.setState({singleGuests: sg});
        //this.state.singleGuests[guest.orderId-1] = guest;
        console.log("guests array", this.state.singleGuests);
    }

    onFormSubmitted = async (event) => {
        event.preventDefault();
        if(!this.validateEmail(this.state.email)){
            console.log("bad email - submit exited!");
            return;
        }
        console.log("form submitted");
        console.log(this.state.singleGuests);
        //const formData = new FormData();
        var data = [];
        this.state.singleGuests.map((e, i) => {
            if(this.state.singleGuests[i].name && this.state.singleGuests[i].name.length > 0) {
                data = [
                    ...data,
                    this.state.singleGuests[i]
                ];
            }

            return true;
        });

        //formData.append('dataArray', JSON.stringify(data));

          try {

            const invData = {
                willAttend: true, 
                rsvpCompleted: true,
                email: this.state.email,
                comment: this.state.comment
            };
    
            await axios.put(`${process.env.REACT_APP_BASE_URL}/invitations/${this.state.invId}`, invData)
            .then(res => {
                //const invitations = res.data;
                //console.log(invitations);
    
                //window.location.replace("/");

                axios.post(`${process.env.REACT_APP_BASE_URL}/invguests/${this.state.invId}`, data)
                .then(resGuests => {
                    const savedGuests = resGuests.data;
                     console.log("savedGuests:::=>>>", savedGuests);
                     this.setState({rsvpCompleted: true});
                     this.setState({submitSuccess: true});
                })
                .catch((error) => {
                    console.log(error);
                });
            })
            .catch((error) => {
                console.log(error);
            });
    
            console.log(data);
          } catch (error) {
            console.error('Error occurred while posting data:', error);
          }

          //this.setState({rsvpCompleted: true});
    }

    render() {

        return(
            <>
            {  
            this.state.rsvpCompleted && this.state.submitSuccess &&
            <div className="ui segment" style={{ backgroundColor: '#e6bdb6'}} >
                <Header>
                    Thank you for submitting your RSVP, {this.state.name}!  A confirmation email is on it's way.
                </Header>
                
                <div>   
                    If you have any questions or concerns, please email Victoria Rajkumar at <a href="mailto:vika_rajkumar@hotmail.com">vika_rajkumar@hotmail.com</a>.
                </div>
                <div>Thank you!</div>
            </div>
 
            }

            {  
            this.state.rsvpCompleted && !this.state.submitSuccess &&
            <div className="ui segment" style={{ backgroundColor: '#e6bdb6'}} >
                <Header>
                    Hello {this.state.name}!  You have already submitted your RSVP.  
                </Header>
                <div>   
                    If you have any issues, please email Victoria Rajkumar at <a href="mailto:vika_rajkumar@hotmail.com">vika_rajkumar@hotmail.com</a>.
                </div>
                <div>Thank you!</div>
            </div>
 
            }

           {  
            !this.state.rsvpCompleted &&
            <div className="ui segment" style={{ backgroundColor: '#e6bdb6'}} >
                <Header>
                    Hello {this.state.name}!  We have reserved {this.state.seats} seats in your honor. 
                </Header>
                <div>   
                    Will you be attending?
                </div>
             
                <div>
                    <Radio id="willAttend_Yes" name="willAttend_Yes" label="Yes" value="true" checked={this.state.willAttend} onChange={this.onWillAttendChanged}></Radio>
                </div>
                <div>
                    <Radio id="willAttend_No" name="willAttend_No" label="No" value="false" checked={!this.state.willAttend && this.state.willAttendFirstTimeClicked} onChange={this.onWillAttendChanged}></Radio>
                </div>
            </div>
 
            }

            {
                !this.state.willAttend && this.state.willAttendFirstTimeClicked &&
                <div className="ui segment" style={{ backgroundColor: '#e6bdb6'}} >

                    <div style={{marginBottom: '20px', marginTop: '20px' }}>
                        Comments / questions:
                    </div>
                    <div style={{marginBottom: '20px', marginTop: '20px' }} >
                 
                 <Input type="text" id='comment_no'
                         name='comment_no'
                         placeholder='Please enter comment / question'
                         value={this.state.comment}
                         onChange={(e) => this.onCommentChanged(e.target.value)} style={{width: '500px'}} />
                 </div>
                    <div style={{marginBottom: '20px', marginTop: '20px' }} >
                    Thank you for your response.
                    </div>
                    <Button onClick={(e) => this.onNoConfirmedButtonClicked()}>Click to Confirm</Button>
                 </div>
            }

            {
 
                this.state.willAttend && !this.state.rsvpCompleted &&
                <div className="ui segment" style={{ backgroundColor: '#e6bdb6'}} >
                    <div style={{marginBottom: '20px', marginTop: '20px' }}>
                        Awesome! please enter name, age and dietary restrictions for each guest:
                    </div>
                    <Form onSubmit={this.onFormSubmitted}>
                    {
                        this.state.singleGuests.map((e, i) => {
                            return (  <RSVPSingleGuest guest={this.state.singleGuests[i]} onSingleGuestChanged={this.onSingleGuestChanged} /> );
                        })

                    }

                    <div style={{marginBottom: '20px', marginTop: '20px' }}>
                        Please provide your email address for confirmation email: 
                    </div>

                    <div style={{marginBottom: '20px', marginTop: '20px' }}>
                 
                 <Input type="text" id='emailAddress' name='emailAddress' 
                         placeholder={'Please enter Email Address'} 
                         value={this.state.email}
                         onChange={(e) => this.onEmailChanged(e.target.value) }  style={{width: '300px'}}/>
 
                   <span style={{color: 'red', marginLeft: '10px'}}>{this.state.emailAddressValidationMessage}
                    </span> 
 
                       
                   

                 </div>
                    <div style={{marginBottom: '20px', marginTop: '20px' }}>
                        Comments / questions:
                    </div>
                    <div style={{marginBottom: '20px', marginTop: '20px' }} >
                 
                 <Input type="text" id='comment'
                         name='comment'
                         placeholder='Please enter comment / question'
                         value={this.state.comment}
                         onChange={(e) => this.onCommentChanged(e.target.value)} style={{width: '500px'}} />
                 </div>
                    <div style={{marginBottom: '20px', marginTop: '20px' }} >

                    </div>

                    <Button type="submit">Submit</Button>

                    </Form>
                    
                </div>
            }
            </>
        );

    }
}

export default RSVPRegistration;