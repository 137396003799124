import React from 'react';
//import { Message, Dimmer, Loader } from 'semantic-ui-react';
import TopSection from './TopSection'
import Countdown from './Countdown'
import LoveStory from './LoveStory'
import PlaceAndTime from './PlaceAndTime'
import LinkToRSVP from './LinkToRSVP'
import Gallery from './Gallery'
import InvitedList from './InvitedList';
import '../css/lovestory.css'
import '../css/fonts.css';
import '../css/gallery.css';
import '../css/topsection.css';

const PageContent = () => {
 
    return (
        <>
        {/* <div style={{display:'block', position:'static', top:'0', flow:'left'}}> */}
        {/* <div style={{width: '100%', border: '1px Solid Red'}}> */}
        <TopSection></TopSection> 
        <Countdown targetDate="2025-06-14T11:00:00" targetTimezone='America/New_York'></Countdown>
        <LoveStory></LoveStory> 
        <PlaceAndTime></PlaceAndTime>
        <LinkToRSVP></LinkToRSVP>
        <Gallery></Gallery> 

        <br/><br/>
         
        {/* </div> */}
        </>
    );

};

export default PageContent;