import React, { useEffect, useState }  from 'react'; 
import { Button, Image, Modal } from 'semantic-ui-react';
 

const images = [
{ src: 'media/20211222_192031.jpg', alt: '' },
//{ src: 'media/20211222_194055.jpg', alt: '' },
{ src: 'media/20220101_115326.jpg', alt: '' },
//{ src: 'media/20220220_122044.jpg', alt: '' },
{ src: 'media/20220222_133022.jpg', alt: '' },
{ src: 'media/20220619_130311.jpg', alt: '' },
{ src: 'media/20220708_084223.jpg', alt: '' },
{ src: 'media/20221105_170758.jpg', alt: '' },
{ src: 'media/20230105_183411.jpg', alt: '' },
//{ src: 'media/20230725_181603.jpg', alt: '' },
//{ src: 'media/20230730_203026.jpg', alt: '' },
{ src: 'media/20230801_095801.jpg', alt: '' },
{ src: 'media/squamish.jpg', alt: '' },
{ src: 'media/20231112_173718.jpg', alt: '' },
//{ src: 'media/20231113_124736.jpg', alt: '' },
{ src: 'media/20240219_143149.jpg', alt: '' },
{ src: 'media/visitLA.jpg', alt: '' },
{ src: 'media/engagement-055.jpg', alt: '' },
{ src: 'media/engagement-095.jpg', alt: '' },
{ src: 'media/engagement-102.jpg', alt: '' },
{ src: 'media/engagement-103.jpg', alt: '' },
{ src: 'media/engagement-158.jpg', alt: '' },
{ src: 'media/engagement-185.jpg', alt: '' },
{ src: 'media/engagement-213.jpg', alt: '' },
];

 

const Gallery = () => {
    const [open, setOpen] = useState(99);
    return (
        <section id="gallery"
            className="gallery-main"
            style={{ border: '0px Solid Orange' }}>
         
                <div className="love-header">
                    Galler&#xE080;
                </div>
          
            <div  className='masonry-outer-container'> 
            <div className="c-content-isotope-gallery c-opt-1 masonry">
                {images.map((image, index) => ( 
                <div key={index}  className="c-content-isotope-item"   onClick={() => setOpen(index)}>
                    <div className="c-content-isotope-image-container masonry-item "   >
                        <img className="c-content-isotope-image" src={image.src} alt=''/>
                        <div className="c-content-isotope-overlay" > 
                            <div className="c-content-isotope-overlay-icon" >
                                <span className="fa ">b</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                ))}

            </div>

            </div>
            <Modal
                open={open>=0&&open<images.length}
                onClose={() => {setOpen(99) ; console.log(open);}}
                dimmer="inverted"
                centered={false}
            >

                <div class="image-container">
                    <div onClick={() => setOpen(99)} class="close-button">&times;</div>
                    <div onClick={() => setOpen(open-1<0?images.length-1:open-1)}  class="arrow-button left-arrow">&#9664;</div>
                    <Image  src={open<99?images[open].src:images[0].src} className="center-image"    />
                    <div onClick={() => setOpen(open+1>=images.length?0:open+1)}  class="arrow-button right-arrow">&#9654;</div>
                </div>

            </Modal>


         </section>
    );
};
 
export default Gallery;
 