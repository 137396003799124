export class SingleGuest {
    constructor(id, invitationId, orderId, name, isUnder19, isUnder12, dietaryRestrictions) {
        this._id = id;
        this.invitationId = invitationId;
        this.orderId = orderId;
        this.name = name;
        this.isUnder19 = isUnder19;
        this.isUnder12 = isUnder12;
        this.dietaryRestrictions = dietaryRestrictions;
    }
}