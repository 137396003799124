import React from 'react';
import axios from 'axios';

export default class InvitedList extends React.Component {
  state = {
    invitations: []
  }

  componentDidMount() {
    console.log(`${process.env.REACT_APP_BASE_URL}/invitations`);
    axios.get(`${process.env.REACT_APP_BASE_URL}/invitations`)
      .then(res => {
        const invitations = res.data;
        this.setState({ invitations });
      })
      .catch((error) => {
        console.log(`${process.env.REACT_APP_BASE_URL}/invitations`);
        console.log(error);
      });
  }

  render() {
    return (
      <ul>
        {
          this.state.invitations
            .map(invitation =>
              <li key={invitation._id}>{invitation.name}, {invitation.email}, {invitation.city}</li>
            )
        } 
      </ul>
    )
  }
}